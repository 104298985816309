<template>
  <validation-observer
    ref="forgotPasswordForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form @submit.prevent="handleSubmit($emit('send-password-reset-link', email))">
      <validation-provider
        v-slot="{ errors }"
        rules="required|email"
        name="email"
      >
        <b-field
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input
            v-model="email"
            type="email"
            placeholder="Email"
            icon="user"
          />
        </b-field>
      </validation-provider>

      <b-field>
        <b-button
          :disabled="invalid"
          native-type="submit"
          type="is-info"
          expanded
        >
          send password reset link
        </b-button>
      </b-field>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {

  name: 'ForgotPasswordForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    email: '',
  }),

};
</script>

<style lang="css" scoped></style>
